import React, {useState, useCallback, Fragment} from 'react'
import {
  Subheading,
  Card,
  FormLayout,
  InlineError,
  TextField,
  Stack,
  Button,
  ButtonGroup,
  Modal,
  ChoiceList,
  Thumbnail,
} from "@shopify/polaris"
import ProductPickerModal from '../../../../../components/productPickerModal'
import selectPayloadToPPData from '../../../../../helpers/selectPayloadToPPData'
import update from 'immutability-helper'
import styled from 'styled-components'

const CouponWrapper = styled.div`
  .coupon-title {
    display: block;
    padding: 5px 0;
  }
  .coupon-items {
    display: flex;
    flex-direction: row;
    gap: 15px;
    .coupon-item:first-of-type {
      flex: 0 0 auto;
    }
    .coupon-item:nth-child(2) {
      width: 125px;
    }
    .coupon-item:last-of-type {
      flex: 1 1 auto;
    }
  }
`
const EventTriggerWrapper = styled.div`
    .trigger-title {
        margin-bottom: 10px;
    }
    .trigger-button-group {
        display: flex ;
        gap: 10px;
        flex-direction: column;
        .active {
          border: 2px solid #008060;
          border-radius: 6px;
        }
    }
`
const FeaturedImageWrapper = styled.div`
  .featued-image-title {
    display: block;
    padding-bottom: 5px;
  }
  .Polaris-Stack__Item:last-of-type {
    display: flex;
    align-items: center
  }
`

function CartProductsSetting(props) {
  const { host, shop, token, state, setState, productPickerShow, error, currency, currencySymbol } = props
  const [productPickerVisible, setProductPickerVisible] = useState(false)
  const [active, setActive] = useState(false);
  const handleModalChange = useCallback(() => setActive(!active), [active]);
  const activator = <Button onClick={handleModalChange}>Change image</Button>;
  const [selectedImage, setSelectedImage] = useState([]);
  const handleImageChange = useCallback((value) => setSelectedImage(value), []);
  const updateFeaturedImage = () => {
    if(selectedImage.length > 0){
      setState(update(state, { featuredImage: { $set: selectedImage[0] }}))
    }
    handleModalChange()
  }

  const [isFirstButtonActive, setIsFirstButtonActive] = useState(!state.discount.sym || state.discount.sym === "$" ? true : false);

  const handleFirstButtonClick = (index) => {
    const discount = {sym:'$', value:''}
    if(Array.isArray(state.product)){
      setState(update(state,{ product: {[index] :{ discount: { $set: discount }}}}))
    }else{
      if (isFirstButtonActive) return;
      setState(update(state, { discount: { $set: discount }}))
      setIsFirstButtonActive(true);
    }

  }

  const handleSecondButtonClick = (index) => {
    const discount = {sym:'%', value:''}
    if(Array.isArray(state.product)){
      setState(update(state,{ product: {[index] :{ discount: { $set: discount }}}}))
    }else{
      if (!isFirstButtonActive) return;
      setState(update(state, { discount: { $set: discount }}))
      setIsFirstButtonActive(false);
    }
  }

  const handleTriggerEventClick = (triggerEvent) => {
    setState(update(state,{ triggerEvent: {$set: triggerEvent }}))
  }
  return (
    <div style={{marginTop: '10px'}}>
      <Card sectioned>
        <Subheading>product</Subheading>
        <FormLayout>
          <Fragment>
            <div className='mockInputContainer'>
              <p className='mockInputLabel' style={{paddingTop:'10px'}}>Offer product</p>
              <div
                className='mockInput'
                onClick={() => setProductPickerVisible(true)}
              >
                {
                  ((!state.variant && !state.variants) || !state.product) ?
                    <span>Select a product</span> :
                    <div>
                      <img
                        src={state.product.image}
                        className='mockInputImage'
                      />
                      {state.variants ?
                        <span>{state.product.title} | {state.variants.map(variant => variant.title).join("/")}</span>
                        :
                        <span>{state.product.title} | {state.variant.title}</span>
                      }
                    </div>
                }
              </div>
              {
                error && error.product ?
                  <InlineError message={error.product} fieldID="product-error" />
                  :
                  false
              }
            </div>
            { "product" === productPickerShow ?
              <ProductPickerModal
                host={host}
                shop={shop}
                token={token}
                open={productPickerVisible}
                onSelect={(selectPayload) => {
                  const data = selectPayloadToPPData(selectPayload)
                  if (!data) {
                    if (state.product) {
                      // Clear selection if one exists
                      setState(update(state, {
                        product: { $set: null },
                        variant: { $set: null },
                        variants: { $set: null },
                      }))
                    }
                    return
                  }

                  setState(update(state, {
                    product: { $set: data.product },
                    variants: { $set: data.variants },
                    featuredImage: { $set: data.product?.images[0]?.originalSrc}
                  }))
                }}
                onClose={() => setProductPickerVisible(false)}
                initialProduct={state?.product ? {product:state.product, variants: state.variants}:null || null}
              />
              :
              false
            }
          </Fragment>

          <CouponWrapper>
            <span className='coupon-title'>Discounts</span>
            <div className='coupon-items'>
              <div className='coupon-item'>
                <ButtonGroup segmented>
                  <Button pressed={isFirstButtonActive} onClick={() => handleFirstButtonClick(0)}>
                    {currencySymbol}
                  </Button>
                  <Button pressed={!isFirstButtonActive} onClick={() => handleSecondButtonClick(0)}>
                    %
                  </Button>
                </ButtonGroup>
              </div>
              <div className='coupon-item'>
                <TextField
                  onChange={(val) => {
                    const newVal = !isFirstButtonActive && val > 100 ? 100 : val
                    setState(update(state,  { discount: { $set: {sym:isFirstButtonActive ? currencySymbol : '%', value:newVal} }}))
                  }}
                  value={state.discount.sym ? String(state.discount.value): state.discount}
                  min={0}
                  max={!isFirstButtonActive ? 100 : null}
                  type={'text'}
                  suffix={isFirstButtonActive ? currency:'%'}
                />
              </div>
            </div>
          </CouponWrapper>
          <EventTriggerWrapper>
            <div className='trigger-title'>
              <span>Trigger event</span>
            </div>
            <div className='trigger-button-group'>
              <div className={"cart" == state.triggerEvent ? 'active':''}>
                <Button fullWidth onClick={() => handleTriggerEventClick('cart')}>
                  Checkout (cart page)
                </Button>
              </div>
              <div className={"products" == state.triggerEvent ? 'active':''}>
                <Button fullWidth onClick={() => handleTriggerEventClick('products')}>
                  Product (product page)
                </Button>
              </div>
            </div>
            <div className="Polaris-Labelled__HelpText"><span>{"cart" == state.triggerEvent ? 'Offer is displayed when the buyer clicks ‘Checkout’ on cart page.':'Offer is displayed when buyer clicks "Add to Cart" on product page.'}</span></div>
          </EventTriggerWrapper>
          <FeaturedImageWrapper>
            <span className='featued-image-title'>Featured image</span>
            <Card>
              <Card.Section>
                <Stack>
                  <Stack.Item fill>
                    <img
                      style={{
                        width: 60,
                        height: 60,
                        objectFit: "contain",
                        objectPosition: "center",
                        border: '1px solid #AEB4B9',
                        borderRadius: '5px'
                      }}
                      src={state.featuredImage && state?.product?.id ? state.featuredImage : 'https://via.placeholder.com/200'}
                    />
                  </Stack.Item>
                  <Stack.Item style={{display:'flex'}} className="test">
                    {
                      state.product &&
                      <Modal
                        activator={activator}
                        open={active}
                        onClose={handleModalChange}
                        title="Images"
                        primaryAction={{
                          content: 'Add image',
                          onAction: updateFeaturedImage,
                        }}
                        secondaryActions={[
                          {
                            content: 'Cancel',
                            onAction: handleModalChange,
                          },
                        ]}
                      >
                        <Modal.Section>

                          <ChoiceList
                            choices={state.product.images ? state.product.images.map(image =>
                            {
                              return {
                                label:
                                  <Thumbnail
                                    source={image.originalSrc}
                                  />,
                                value: image.originalSrc}
                            }): {label:
                                <Thumbnail
                                  source={state.product.image}
                                />,
                              value: state.product.image}}
                            selected={selectedImage}
                            onChange={handleImageChange}
                          />

                        </Modal.Section>
                      </Modal>
                    }
                  </Stack.Item>
                </Stack>
              </Card.Section>
            </Card>
          </FeaturedImageWrapper>
        </FormLayout>
      </Card>
    </div>
  )
}

export default CartProductsSetting;