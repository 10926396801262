import React from 'react'
import { 
    Subheading,
    Card,
} from "@shopify/polaris"
import styled from 'styled-components'
import SmartRules from '../smartRules'
import CartProductsSetting from './cartProductsSetting'
import CartProductsSettingV1 from './v1/cartProductsSetting'
import CartCustomization from './customization';

const ManualRulesWrapper = styled.div`
    margin-top: 20px;
`
function ManualRules(props) {
    const { host, shop, token, addSmartRule, state, setState, productPickerShow, setProductPickerShow, error, productEdit, setProductEdit, currency, currencySymbol, checkoutOfferType } = props
    return (
        <ManualRulesWrapper>
          {
            state?.cartType && state.cartType === 'page'
              ? <CartProductsSetting host={host} shop={shop} token={token} state={state} setState={setState} productPickerShow={productPickerShow} error={error} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol}/>
              : <CartProductsSettingV1 host={host} shop={shop} token={token} state={state} setState={setState} productPickerShow={productPickerShow} error={error} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol}/>
          }
          <div style={{marginTop: '20px'}}>

          {
            state?.cartType && state.cartType === 'page' &&
              <CartCustomization state={state} setState={setState} checkoutOfferType={checkoutOfferType}/>
          }

          <Card sectioned>
            <div className='manual-rules'>
                <Subheading>Smart Rules</Subheading>
                <div style={{paddingTop:'10px'}}>
                  <SmartRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} type={"checkout"}/>
                </div>
            </div>
          </Card>
          </div>
        </ManualRulesWrapper>
    )
}

export default ManualRules;